import React, { useState } from 'react';
import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import {
  Badge,
  Card,
  Image,
  Table,
  Tooltip,
  OverlayTrigger,
  Spinner,
  FormCheck,
} from 'react-bootstrap';
import { useNavigate, generatePath } from 'react-router-dom';
import Moment from 'moment';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';
import { MedicalDocumentActions } from '#components';
import { MEDICAL_DOCUMENT_ACTION_TYPES } from '#const';
import { alert } from '#utils';
import { useBreakpoint, BREAKPOINT } from '#hooks';
import { Routes } from '#Routes';
import { ReactComponent as LaboratoryLogo } from '#assets/laboratory.svg';
import { ReactComponent as DiagnosisLogo } from '#assets/diagnosis.svg';

export const MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS = {
  SELECT: 'SELECT',
  INFO: 'INFO',
  CREATED: 'CREATED',
  DOCUMENT_DATE: 'DOCUMENT_DATE',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
  PROCESS_TYPE: 'PROCESS_TYPE',
  PROCESS_STATUS: 'PROCESS_STATUS',
  REPORT_STATUS: 'REPORT_STATUS',
  CONFIRMED: 'CONFIRMED',
  ACTIONS: 'ACTIONS',
};

export const MedicalDocumentsList = ({
  data = [],
  onTagClick,
  selectedDocuments: selected = [],
  onSelectionChange,
  maxSelectionNumber,
  onDocumentClick,
  display: displayArray = [],
  onSort,
  sortField,
  actions = [
    MEDICAL_DOCUMENT_ACTION_TYPES.VIEW,
    MEDICAL_DOCUMENT_ACTION_TYPES.EDIT,
    MEDICAL_DOCUMENT_ACTION_TYPES.PROCESS,
    MEDICAL_DOCUMENT_ACTION_TYPES.REPORT,
    MEDICAL_DOCUMENT_ACTION_TYPES.DELETE,
  ],
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint();
  const display = displayArray.reduce((acc, display) => ({ ...acc, [display]: true }), {});

  const showImageGallery = ({ id, images }) => {
    alert
      .fire({
        title: `${t('medical-documents-list.image-gallery.title')} #${id}`,
        showCancelButton: true,
        confirmButtonText: t('medical-documents-list.image-gallery.confirm-button'),
        cancelButtonText: t('medical-documents-list.image-gallery.cancel-button'),
        html: (
          <ImageGallery
            showFullscreenButton={false}
            showPlayButton={false}
            showNav={false}
            items={images?.map((image) => {
              return {
                original: image.signed_url,
                thumbnail: image.thumbnail_signed_url,
              };
            })}
          />
        ),
      })
      .then((result) => {
        if (result.isConfirmed) {
          navigate(generatePath(Routes.MedicalDocument.path, { id }));
        }
      });
  };

  const getReportStatusText = (status) => {
    if (status === 'NOT_STARTED') return t('medical-documents-list.report-status.not-started');
    else if (status === 'IN_PROGRESS') return t('medical-documents-list.report-status.in-progress');
    else if (status === 'FAILED') return t('medical-documents-list.report-status.failed');
    else if (status === 'FINISHED') return t('medical-documents-list.report-status.finished');
  };

  const getProcessStatusText = (status) => {
    if (status === 'NOT_STARTED') return t('medical-documents-list.process-status.not-started');
    else if (status === 'IN_PROGRESS')
      return t('medical-documents-list.process-status.in-progress');
    else if (status === 'FAILED') return t('medical-documents-list.process-status.failed');
    else if (status === 'FINISHED') return t('medical-documents-list.process-status.finished');
  };

  const isSelected = (id) => {
    return selected.indexOf(id) > -1;
  };

  const changeSelection = ({ id, checked }) => {
    if (checked) {
      // console.log('aaaa ', selected.length, maxSelectionNumber, selected.length <= maxSelectionNumber)
      if (selected.length < maxSelectionNumber) {
        // setSelected([...selected, id]);
        onSelectionChange?.({ id, checked });
      } else {
        alert.fire({
          icon: 'warning',
          title: `${t('medical-documents-list.max-documents-reached')}`,
          text: t(
            'medical-documents-list.max-documents-message-1',
            { maxSelectionNumber },
            'medical-documents-list.max-documents-message-2',
          ),
          confirmButtonText: 'Ok',
        });
      }
    } else {
      if (selected.indexOf(id) >= 0) {
        selected.splice(selected.indexOf(id), 1);
        // setSelected([...selected]);
        onSelectionChange?.({ id, checked });
      }
    }
  };

  const onItemClick = (id) => {
    // If Medical Documents selection is enabled then update selected items
    if (display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.SELECT]) {
      changeSelection({ id, checked: !isSelected(id) });
    }
    onDocumentClick?.(id);
  };

  const handleSort = (field) => {
    // If already sorting by this field, toggle direction
    if (sortField === field) {
      onSort?.(`-${field}`);
    } else if (sortField === `-${field}`) {
      onSort?.(null); // Clear sorting
    } else {
      onSort?.(field); // Start with ascending
    }
  };

  const getSortIcon = (field) => {
    if (sortField === field) {
      return '↑';
    } else if (sortField === `-${field}`) {
      return '↓';
    }
    return '↕';
  };

  const TableRow = (document) => {
    const {
      id,
      uuid,
      images,
      name,
      tags,
      created,
      date,
      document_type,
      confirmed,
      process_type,
      process_status,
      report_status,
    } = document;

    return (
      <tr
        className={`border-bottom`}
        style={{
          background: `${isSelected(id) && '#ddd'}`,
          cursor: `${display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.SELECT] && 'pointer'}`,
        }}
        onClick={(event) => {
          if (display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.SELECT]) {
            event.stopPropagation();
            onItemClick(id);
          }
        }}
      >
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.SELECT] && (
          <td className='border-bottom'>
            <FormCheck type='checkbox' className='dashboard-check'>
              <FormCheck.Input
                id={`user-${id}`}
                checked={isSelected(id)}
                onChange={(event) => {
                  changeSelection({ id: document.id, checked: event.target.checked });
                }}
              />
              <FormCheck.Label htmlFor={`user-${id}`} />
            </FormCheck>
          </td>
        )}
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.INFO] && (
          <td className='d-flex align-items-center'>
            <Card.Link
              style={{ position: 'relative', minWidth: '3rem', minHeight: '3rem' }}
              onClick={(event) => {
                event.stopPropagation();
                showImageGallery({ id, images });
              }}
            >
              {images.length > 0 ? (
                images.map((image, i) => (
                  <Image
                    key={image.uuid}
                    src={image.thumbnail_signed_url}
                    className='avatar me-3'
                    style={{
                      border: '1px solid lightgrey',
                      boxShadow: '0px 0px 5px lightgrey',
                      position: 'absolute',
                      left: `${(i - Math.floor(images.length / 2)) * 10}px`,
                      top: `${images.length === 1 ? 0 : i % 2 ? -5 : 5}px`,
                      minWidth: '3rem',
                      minHeight: '3rem',
                      borderRadius: '5px',
                    }}
                  />
                ))
              ) : document_type === 'LAB' ? (
                <div
                  className='avatar d-flex align-items-center justify-content-center fw-bold rounded me-3'
                  style={{ minWidth: '3rem', minHeight: '3rem' }}
                >
                  <LaboratoryLogo></LaboratoryLogo>
                </div>
              ) : (
                <div
                  className='avatar d-flex align-items-center justify-content-center fw-bold rounded me-3'
                  style={{ minWidth: '3rem', minHeight: '3rem' }}
                >
                  <DiagnosisLogo></DiagnosisLogo>
                </div>
              )}
            </Card.Link>
            <Card.Link>
              <div className='d-block'>
                <span className='fw-bold'>{name}</span>
                <div className='text-gray d-flex flex-wrap' style={{ fontSize: '1.1rem' }}>
                  {tags?.map((tag) => (
                    <Badge key={tag} bg='primary' className='m-1' onClick={() => onTagClick?.(tag)}>
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>
            </Card.Link>
          </td>
        )}
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.CREATED] && (
          <td>
            <span className='fw-normal'>{Moment(created).format('y-MM-DD (HH:mm)')}</span>
          </td>
        )}
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.DOCUMENT_DATE] && (
          <td>
            <span className='fw-normal'>{Moment(date).format('y-MM-DD')}</span>
          </td>
        )}
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.DOCUMENT_TYPE] && (
          <td>
            <span className='fw-normal'>{document_type}</span>
          </td>
        )}
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.PROCESS_TYPE] && (
          <td>
            <span className='fw-normal'>
              <span className={`fw-normal`}>{process_type}</span>
            </span>
          </td>
        )}
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.PROCESS_STATUS] && (
          <td className='text-center'>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='m-0'>{getProcessStatusText(process_status)}</Tooltip>}
            >
              {process_status === 'NOT_STARTED' ? (
                <InformationCircleIcon className='icon icon-xs text-warning me-1' />
              ) : process_status === 'IN_PROGRESS' ? (
                <Spinner style={{ height: '20px', width: '20px' }} />
              ) : process_status === 'FINISHED' ? (
                <CheckCircleIcon className='icon icon-xs text-success me-1' />
              ) : (
                <XCircleIcon className='icon icon-xs text-danger me-1' />
              )}
            </OverlayTrigger>
          </td>
        )}
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.REPORT_STATUS] && (
          <td className='text-center'>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='m-0'>{getReportStatusText(report_status)}</Tooltip>}
            >
              {report_status === 'NOT_STARTED' ? (
                <InformationCircleIcon className='icon icon-xs text-warning me-1' />
              ) : report_status === 'IN_PROGRESS' ? (
                <Spinner style={{ height: '20px', width: '20px' }} />
              ) : report_status === 'FINISHED' ? (
                <CheckCircleIcon className='icon icon-xs text-success me-1' />
              ) : (
                <XCircleIcon className='icon icon-xs text-danger me-1' />
              )}
            </OverlayTrigger>
          </td>
        )}
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.CONFIRMED] && (
          <td className='text-center'>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip className='m-0'>
                  {confirmed
                    ? t('medical-documents-list.confirmed-status.confirmed')
                    : t('medical-documents-list.confirmed-status.unconfirmed')}
                </Tooltip>
              }
            >
              {confirmed ? (
                <CheckCircleIcon className='icon icon-xs text-success me-1' />
              ) : (
                <InformationCircleIcon className='icon icon-xs text-warning me-1' />
              )}
            </OverlayTrigger>
          </td>
        )}
        {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.ACTIONS] && (
          <td className='text-center'>
            <MedicalDocumentActions
              actions={actions}
              MedicalDocument={{ id, uuid, process_status, report_status, confirmed }}
            />
          </td>
        )}
      </tr>
    );
  };

  return (
    <div className='table-responsive'>
      <Table hover className='align-items-center'>
        <thead>
          <tr>
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.SELECT] && (
              <th className='border-bottom'>Select</th>
            )}
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.INFO] && (
              <th className='border-bottom'>
                {t('medical-documents-list.table-head.view-tags')}
              </th>
            )}
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.CREATED] && (
              <th className='border-bottom'>
                {t('medical-documents-list.table-head.created-date')}
              </th>
            )}
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.DOCUMENT_DATE] && (
              <th 
                className='border-bottom' 
                style={{ cursor: 'pointer' }}
                onClick={() => handleSort('date')}
              >
                {t('medical-documents-list.table-head.document-date')}
                <span className="ms-1">{getSortIcon('date')}</span>
              </th>
            )}
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.DOCUMENT_TYPE] && (
              <th className='border-bottom'>{t('medical-documents-list.table-head.type')}</th>
            )}
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.PROCESS_TYPE] && (
              <th className='border-bottom'>
                {t('medical-documents-list.table-head.process-type')}
              </th>
            )}
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.PROCESS_STATUS] && (
              <th className='border-bottom text-center'>
                {t('medical-documents-list.table-head.processing')}
              </th>
            )}
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.REPORT_STATUS] && (
              <th className='border-bottom text-center'>
                {t('medical-documents-list.table-head.report-ai')}
              </th>
            )}
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.CONFIRMED] && (
              <th className='border-bottom text-center'>
                {t('medical-documents-list.table-head.confirmed')}
              </th>
            )}
            {display[MEDICAL_DOCUMENTS_LIST_DISPLAY_OPTIONS.ACTIONS] && (
              <th className='border-bottom text-center'>
                {t('medical-documents-list.table-head.actions')}
              </th>
            )}
          </tr>
        </thead>
        <tbody className='border-0'>
          {data?.results?.map?.((u) => (
            <TableRow key={`user-${u.id}`} {...u} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
