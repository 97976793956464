import React, { useEffect } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { useFirstRender } from '#hooks';
import { CategoryFields } from './CategoryFields';

export function DiagnosisForm({ form }) {
  const { t } = useTranslation();
  const { register, control } = form;
  const isFirstRender = useFirstRender();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'json_content',
    defaultValues: {
      json_content: [{ category_name: '', values: [{ name: '', value: '' }] }],
    },
  });

  useEffect(() => {
    if (isFirstRender && fields.length < 1) {
      append({ category_name: '', values: [{ name: '', value: '' }] });
    }
  }, [isFirstRender, append, fields]);

  return (
    <>
      <Card className='mb-4'>
        <Card.Header className='text-center'>
          <h4>{t('medical-document.diag')}</h4>
        </Card.Header>
        <Card.Body>
          <Row className='align-items-center'>
            <Col xs={12} className='mb-3'>
              <Form.Group id='note'>
                <Form.Label>Text Content</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={12}
                  id='lab-note'
                  name='lab-note'
                  {...register('text_content', { required: false })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {fields.map((category, index) => (
        <CategoryFields
          key={category.id}
          categoryIndex={index}
          control={control}
          register={register}
          onRemove={remove}
        />
      ))}

      <div className='d-grid gap-2 mb-4'>
        <Button
          variant='outline-primary'
          size='lg'
          className='animate-up-2'
          onClick={() => append({ category_name: '', values: [{ name: '', value: '' }] })}
        >
          Add Category
        </Button>
      </div>
    </>
  );
}
