import React, { useEffect } from 'react';
import { Card, Form, Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';

export const CategoryFields = ({ categoryIndex, control, register, onRemove }) => {
  const { t } = useTranslation();
  const {
    fields: valueFields,
    append: appendValue,
    remove: removeValue,
  } = useFieldArray({
    control,
    name: `json_content.${categoryIndex}.values`,
  });

  useEffect(() => {
    if (valueFields.length === 0) {
      appendValue({ name: '', value: '' });
    }
  }, [valueFields.length, appendValue]);

  return (
    <Card className='mb-4'>
      <Card.Header className='text-center d-flex justify-content-between align-items-center'>
        <Form.Group className='flex-grow-1 me-2'>
          <Form.Control
            type='text'
            placeholder='Category Name'
            {...register(`json_content.${categoryIndex}.category_name`)}
          />
        </Form.Group>
        <Button
          variant='close'
          className='ms-2'
          aria-label='Close'
          onClick={() => onRemove(categoryIndex)}
        />
      </Card.Header>
      <Card.Body>
        <Table responsive className='mb-2'>
          <thead className='bg-light border-top'>
            <tr>
              <th className='border-0 text-left' style={{ width: '50%' }}>
                {t('medical-document.name')}
              </th>
              <th className='border-0 text-right' style={{ width: '50%' }}>
                {t('medical-document.value')}
              </th>
              <th className='border-0 text-center' style={{ width: '100px' }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody className='border-0'>
            {valueFields.map((field, valueIndex) => (
              <tr key={field.id} className='border-bottom'>
                <td>
                  <Form.Control
                    type='text'
                    placeholder='Name'
                    {...register(`json_content.${categoryIndex}.values.${valueIndex}.name`)}
                  />
                </td>
                <td>
                  <Form.Control
                    type='text'
                    placeholder='Value'
                    {...register(`json_content.${categoryIndex}.values.${valueIndex}.value`)}
                  />
                </td>
                <td className='text-center'>
                  <Button
                    variant='close'
                    aria-label='Close'
                    onClick={() => {
                      if (valueFields.length === 1) {
                        onRemove(categoryIndex);
                      } else {
                        removeValue(valueIndex);
                      }
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Button
          variant='outline-primary'
          size='sm'
          onClick={() => appendValue({ name: '', value: '' })}
          className='mt-2'
        >
          Add Value
        </Button>
      </Card.Body>
    </Card>
  );
};
