import React from 'react';
import { Col, Badge, Table } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Document } from '#atoms';

export const MedicalDocument = ({ data, hideAI }) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Document.Title
        badge={{
          text: data?.process_type,
          bg: data?.process_type === 'MANUAL' ? 'warning' : 'success',
        }}
      >
        {data?.document_type === 'LAB' ? t('medical-document.lab') : t('medical-document.diag')}
      </Document.Title>

      <Document.Information>
        <>
          <Col xs={12} className='mb-2'>
            {t('medical-document.type')} <span className='fw-bold'>{data?.document_type}</span>
          </Col>

          <Col xs={12} className='mb-2'>
            {t('medical-document.processing')} <span className='fw-bold'>{data?.process_type}</span>
          </Col>

          <Col xs={12} className='mb-2'>
            {t('medical-document.processing-finished')}{' '}
            <span className='fw-bold'>{data?.process_status === 'FINISHED' ? 'YES' : 'NO'}</span>
          </Col>

          <Col xs={12} className='mb-2'>
            {data?.tags?.map((tag) => (
              <Badge key={tag} bg='primary' className='m-1' style={{ fontSize: '1rem' }}>
                {tag}
              </Badge>
            ))}
          </Col>
        </>
        <>
          <Col xs={6} className='mb-2'>
            {t('medical-document.date-created')}
          </Col>
          <Col xs={6} className='mb-2'>
            <span className='fw-bold'>{moment(data?.created).format('y-MM-DD HH:mm')}</span>
          </Col>

          <Col xs={6} className='mb-2'>
            {t('medical-document.date-modified')}
          </Col>
          <Col xs={6} className='mb-2'>
            <span className='fw-bold'>{moment(data?.modified).format('y-MM-DD HH:mm')}</span>
          </Col>

          <Col xs={6} className='mb-2'>
            {t('medical-document.date-issued')}
          </Col>
          <Col xs={6} className='mb-2'>
            <span className='fw-bold'>{moment(data?.date).format('y-MM-DD')}</span>
          </Col>
        </>
      </Document.Information>

      <Document.Loader
        isLoading={data?.process_status === 'IN_PROGRESS'}
        text='Document is processing...'
      />

      {data?.json_content?.length > 0 && data?.document_type === 'DIAG' && (
        <div className='mb-4'>
          <h4 className='text-center mb-4'>{t('medical-document.extracted-structured-data')}</h4>
        </div>
      )}

      {data?.json_content?.map((properties, i) => (
        <div key={i} className='mb-5'>
          <h5>{properties.category_name}</h5>
          <Table responsive className='mb-0'>
            <thead className='bg-light border-top'>
              <tr>
                <th className='border-0 text-left' style={{ width: '50%' }}>
                  {t('medical-document.name')}
                </th>
                <th className='border-0 text-right' style={{ width: '50%' }}>
                  {t('medical-document.value')}
                </th>
                {data?.document_type === 'LAB' && (
                  <>
                    <th className='border-0'>{t('medical-document.unit')}</th>
                    <th className='border-0'>{t('medical-document.reference')}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody className='border-0'>
              {properties.values?.map(({ name, value, unit, reference }, i) => (
                <tr key={i} className='border-bottom'>
                  <th className='h6 text-left fw-bold' style={{ width: '50%' }}>
                    {name}
                  </th>
                  <td className='text-right' style={{ width: '50%' }}>
                    {value}
                  </td>
                  {data?.document_type === 'LAB' && (
                    <>
                      <td>{unit}</td>
                      {reference && Array.isArray(reference) && (
                        <td>
                          {reference[0]} - {reference[1]}
                        </td>
                      )}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}

      <Document.Text title={t('medical-document.content')} content={data?.text_content} />

      {!hideAI && (
        <Document.Text
          title={t('medical-document.ai-report-title')}
          content={data?.report_content || t('medical-document.document-no-health-report')}
        />
      )}

      <Document.Loader
        isLoading={data?.report_status === 'IN_PROGRESS'}
        text={t('medical-document.ai-report-generating')}
      />

      <div className='d-flex justify-content-end mt-4'>
        {data?.approved ? (
          <div>
            <h5>{t('medical-document.document-approved')}</h5>
            <span className='ms-2'>{data?.approved_by}</span>
          </div>
        ) : (
          <h5>{t('medical-document.document-not-approved')}</h5>
        )}
      </div>
    </Document>
  );
};
