import React from 'react';
import ApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

export const BarGraphChart = ({ data }) => {
  const { t } = useTranslation();

  if (!data || !Array.isArray(data) || data.length === 0) {
    return <div>{t('laboratory-tests.chart-invalid-data')}</div>;
  }

  const firstDataSet = data[0];
  if (!firstDataSet || typeof firstDataSet !== 'object' || !Array.isArray(firstDataSet.values)) {
    return <div>{t('laboratory-tests.chart-invalid-data-structure')}</div>;
  }

  const chartSeries = data.map((d) => ({
    name: d.label || 'Unnamed',
    data: d.values.map((v) => v.value),
  }));

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
      zoom: { enabled: false },
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'straight' },
    title: {
      text: t('laboratory-tests.history-modal-title', { testName: firstDataSet.label }),
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: firstDataSet.values.map((v) => v.date),
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
    },
  };

  return <ApexChart options={chartOptions} series={chartSeries} type='bar' height={350} />;
};
